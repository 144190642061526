var notice = [
  {
    title: '木渎古镇',
    name: 'mudu',
    description:
      '中国历史文化名镇  江南园林古镇 中国AAAA级旅游景区 乾隆六次到过的地方',
    imgUrl: 'notice/001_mudu.png',
    urls: ['mudu/00_木渎景区_00.jpg',
      'mudu/01_严家花园_00.jpg',
      'mudu/02_虹饮山房_00.jpg',
      'mudu/03_古松园_00.jpg',
      'mudu/04_榜眼府第_00.jpg',
      'mudu/05_明月寺_00.jpg',
      'mudu/06_游客中心_00.jpg'

    ]
  },
  {
    title: '东山古镇',
    name: 'dongshan',
    description:
      '"中国历史文化名镇"、全国环境优美镇。',
    imgUrl: 'notice/002_dongshan.png',
    urls: ['dongshan/00_东山景区_00.jpg',
      'dongshan/00_东山景区_01.jpg',
      'dongshan/00_东山景区_02.jpg',
      'dongshan/00_东山景区_03.jpg',
      'dongshan/00_东山景区_04.jpg',
      'dongshan/00_东山景区_05.jpg',
      'dongshan/00_东山景区_06.jpg',
      'dongshan/00_东山景区_07.jpg',
      'dongshan/00_东山景区_08.jpg',
      'dongshan/00_东山景区_09.jpg',
      'dongshan/00_东山景区_10.jpg',
      'dongshan/00_东山景区_11.jpg',
      'dongshan/00_东山景区_12.jpg',
      'dongshan/00_东山景区_13.jpg',
      'dongshan/00_东山景区_14.jpg',
      'dongshan/00_东山景区_15.jpg',
      'dongshan/00_东山景区_16.jpg',
      'dongshan/00_东山景区_17.jpg',
      'dongshan/00_东山景区_18.jpg',
      'dongshan/00_东山景区_19.jpg',
      'dongshan/00_东山景区_20.jpg',
      'dongshan/00_东山景区_21.jpg',
      'dongshan/00_东山景区_22.jpg',
      'dongshan/00_东山景区_23.jpg',
      'dongshan/00_东山景区_25.jpg',
      'dongshan/00_东山景区_26.jpg',
      'dongshan/00_东山景区_27.jpg'
    ]
  },
  {
    title: '穹窿山',
    name: 'qionglongshan',
    description:
      '天下第一智慧山、乾隆六次祈福地、《孙子兵法》诞生地',
    imgUrl: 'notice/003_qionglongshan.png',
    urls: ['qionglongshan/01_穹窿山景区_00.jpg', 'qionglongshan/01_穹窿山景区_01.jpg', 'qionglongshan/01_穹窿山景区_02.jpg',
      'qionglongshan/01_穹窿山景区_03.jpg', 'qionglongshan/01_穹窿山景区_04.jpg', 'qionglongshan/01_穹窿山景区_05.jpg',
      'qionglongshan/01_穹窿山景区_06.jpg', 'qionglongshan/01_穹窿山景区_07.jpg', 'qionglongshan/01_穹窿山景区_08.jpg',
      'qionglongshan/01_穹窿山景区_09.jpg', 'qionglongshan/01_穹窿山景区_10.jpg', 'qionglongshan/01_穹窿山景区_11.jpg', 'qionglongshan/01_穹窿山景区_12.jpg'
    ]
  },
  {
    title: '旺山景区',
    name: 'wangshan',
    description:
      '景区青山怀抱，绿树成荫，环境幽雅，内现有九龙潭水库、九龙戏水瀑布、揖山亭、健康长廊、观光步道、银杏果园、旺山茶叶基地等景点',
    imgUrl: 'notice/004_wangshan.png',
    urls: [
      'wangshan/02_旺山景区_00.jpg',
      'wangshan/02_旺山景区_01.jpg',
      'wangshan/02_旺山景区_02.jpg',
      'wangshan/02_旺山景区_03.jpg',
      'wangshan/02_旺山景区_04.jpg',
      'wangshan/02_旺山景区_05.jpg',
      'wangshan/02_旺山景区_06.jpg',
      'wangshan/02_旺山景区_07.jpg'

    ]
  },
  {
    title: '太湖湖滨湿地',
    name: 'taihuhubin',
    description:
      '集水生动植物观赏、生态湿地景观、科普宣教活动、栈道游览等旅游项目于一体的自然生态湿地',
    imgUrl: 'notice/005_taihuhubin.png',
    urls: [
      'taihuhubin/03_太湖湖滨湿地_00.jpg',
      'taihuhubin/03_太湖湖滨湿地_01.jpg',
      'taihuhubin/03_太湖湖滨湿地_02.jpg',
      'taihuhubin/03_太湖湖滨湿地_03.jpg',
      'taihuhubin/03_太湖湖滨湿地_04.jpg'

    ]
  },
  {
    title: '甪直古镇',
    name: 'luzhi',
    description:
      '甪直古镇位于苏州市区东南25公里处，跨过“桥都”的数座三步二桥（即双桥），看河边白墙黑瓦的建筑，寻找中学语文课本里《多收了三五斗》的故事发生地。',
    imgUrl: 'notice/006_luzhi.png',
    urls: [
      'luzhi/00_甪直景区_00.jpg',
      'luzhi/01_保圣寺_00.jpg',
      'luzhi/02_叶圣陶纪念馆_00.jpg',
      'luzhi/03_沈宅_00.jpg',
      'luzhi/04_万盛米行_00.jpg',
      'luzhi/05_水乡博物馆_00.jpg',
      'luzhi/06_萧宅_00.jpg',
      'luzhi/07_王韬纪念馆_00.jpg',
      'luzhi/08_历史博物馆_00.jpg'

    ]
  },
  {
    title: '天池山景区',
    name: 'tianchishan',
    description:
      '天池山位于苏州城西15公里处的木渎藏书。为江南原始、古朴、自然的原生态旅游胜地。从古至今就以山美，林秀，石奇，泉幽而著称。',
    imgUrl: 'notice/007_tianchishan.png',
    urls: [
      'tianchishan/00_天池山_00.jpg',
      'tianchishan/00_天池山_01.jpg',
      'tianchishan/00_天池山_02.jpg',
      'tianchishan/01_花山_00.jpg',
      'tianchishan/01_花山_01.jpg',
      'tianchishan/02_白象湾_00.jpg',
      'tianchishan/02_白象湾_01.jpg',
      'tianchishan/02_白象湾_02.jpg',
      'tianchishan/02_白象湾_03.jpg',
      'tianchishan/02_白象湾_04.jpg',
      'tianchishan/02_白象湾_05.jpg'

    ]
  },
  {
    title: '光福景区',
    name: 'guangfu',
    description:
      '香雪海是康熙乾隆赏梅的地方，以赏梅历史最为悠久、梅文化底蕴最为深厚而列为中国四大赏梅胜地之一',
    imgUrl: 'notice/008_guangfu.png',
    urls: [
      'guangfu/00_光福景区_00.jpg',
      'guangfu/01_香雪海_00.jpg',
      'guangfu/01_香雪海_01.jpg',
      'guangfu/01_香雪海_02.jpg',
      'guangfu/01_香雪海_03.jpg',
      'guangfu/01_香雪海_04.jpg',
      'guangfu/01_香雪海_05.jpg',
      'guangfu/02_司徒庙_00.jpg',
      'guangfu/02_司徒庙_01.jpg',
      'guangfu/02_司徒庙_02.jpg',
      'guangfu/02_司徒庙_03.jpg',
      'guangfu/02_司徒庙_04.jpg',
      'guangfu/02_司徒庙_05.jpg',
      'guangfu/02_司徒庙_06.jpg',
      'guangfu/03_铜观音寺_00.jpg',
      'guangfu/03_铜观音寺_01.jpg',
      'guangfu/03_铜观音寺_02.jpg',
      'guangfu/03_铜观音寺_03.jpg',
      'guangfu/03_铜观音寺_04.jpg',
      'guangfu/03_铜观音寺_05.jpg',
      'guangfu/03_铜观音寺_06.jpg'
    ]
  },
  {
    title: '西山景区',
    name: 'xishan',
    description:
      '林屋洞、石公山、开心农场',
    imgUrl: 'notice/009_xishan.png',
    urls: [
      'xishan/01_林屋洞_00.jpg',
      'xishan/02_石公山_00.jpg'

    ]
  }
]
export default notice
