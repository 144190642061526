<template>
  <div class="home">
    <el-row>
      <el-col v-for="(item, index) in msg" :key="index">
        <div style="position: relative">
          <el-card shadow="always" :body-style="{ padding: '0px' }">
            <div @click="navTo(item.name)">
              <el-image style="width: 100%; display: flex" :src="require('@/assets/' + item.imgUrl)" lazy />
              <div class="title">{{ item.title }}</div>
              <div class="description">
                {{ item.description }}
              </div>
            </div>

          </el-card>
        </div>
      </el-col>

    </el-row>

  </div>
</template>

<script>
// @ is an alias to /src
import notice from '@/api/notice'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      msg: []
    }
  },
  mounted() {
    this.msg = notice
  },
  methods: {
    navTo(name) {
      this.$router.push({ name: 'info', params: { name: name }})
      console.log(name)
    }
  }
}
</script>

<style scoped>
.description {
  color: rgb(255, 250, 250);
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 0.7rem;
  /* font-weight: bold; */
}
.title {
  color: white;
  position: absolute;
  bottom: 3.3rem;
  left: 0.5rem;
  font-size: 1.4rem;
  font-weight: bolder;
}
h3 {
  margin: 40px 0 0;
}
</style>
